import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Head from '../components/Head';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { ButtonPrimary } from '../components/Buttons';
import { Field, Label, Input } from '../components/Form';

import GlobalStyle from '../GlobalStyle';

export default ({ data }) => {
  const [bid, setBid] = useState('');
  const [bcode, setBcode] = useState('');

  return (
    <div style={{ position: 'relative' }}>
      <Head pageTitle="Stornierung" pageUrl="/cancel" />
      <Header absolute />

      <Container>
        <p>
          Sie möchten Ihre Reservierung stornieren? Über das folgende Formular
          können Sie Ihre Stornierung einreichen. Die hierfür notwendigen Daten
          finden Sie in der Ihnen zugegangenen Bestätigungs E-Mail. Bei Fragen
          senden Sie uns{' '}
          <a href="https://www.schlosshotel-gedern.de/kontakt-anreise/">
            gerne eine Nachricht oder rufen Sie uns an
          </a>
          .
        </p>

        <form
          method="get"
          action="https://v4.ibe.dirs21.de/channels/schlosshotel-gedernde/"
        >
          <input type="hidden" name="mode" value="cancel" />

          <Field>
            <Label htmlFor="form-bid" show={Boolean(bid)}>
              Buchungsnummer
            </Label>
            <Input
              required
              id="form-bid"
              name="bid"
              placeholder="Buchungsnummer"
              value={bid}
              onChange={e => setBid(e.target.value)}
            ></Input>
          </Field>
          <Field>
            <Label htmlFor="form-bCode" show={Boolean(bcode)}>
              Storno- oder Änderungs-PIN
            </Label>
            <Input
              required
              id="form-bCode"
              name="bCode"
              placeholder="Storno- oder Änderungs-PIN"
              value={bcode}
              onChange={e => setBcode(e.target.value)}
            ></Input>
          </Field>
          <ButtonPrimary type="submit" full marginTop={40}>
            Buchung suchen
          </ButtonPrimary>
        </form>
      </Container>

      <Footer
        image={data.prismicHomepage.data.outro}
        address={data.prismicHomepage.data.address.html}
      />
      <GlobalStyle />
    </div>
  );
};

const Container = styled.div`
  margin: 0 auto 80px;
  padding: 0 2rem;
  max-width: 650px;

  a {
    color: inherit;
  }
`;

export const pageQuery = graphql`
  {
    prismicHomepage {
      data {
        address {
          html
        }
        outro {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
